import * as config from "./config";
import * as pageProcessing from "./pageProcessing";
import * as eventProcessing from "./eventProcessing";
import { validateDataObj } from "./utilities";
import * as pubSub from "./pubSub";
import * as pubSub_Compatibility from "./pubSub_Compatibility";
import * as appData from "./appData";
import * as idservice from './idservice';
import * as pagehide from './pagehide';
import * as storage from "../src/storage";

// Conditional imports.  If not used based on site conditions below webpack will strip these from the bundle.
import * as perimeterx from "./perimeterx";

let setupModules = async function() {

	if ( config.featureEnabled( 'ecridsync' ) ) {
		idservice.init();
	}

};

let getTempEventData = function ( digitalData ) {

	let tempEventData = ( typeof digitalData.event !== 'undefined' && digitalData.event.constructor === Array && digitalData.event.length ? digitalData.event : [] );

	return tempEventData;

};

let exposeItemsOnInterface = function () {

	// Used to pass events to core analytics upon user action or confirmation of successful action.
	_T.sendPageDataToAnalytics = pageProcessing.sendPageDataToAnalytics;
	_T.sendEventDataToAnalytics = eventProcessing.sendEventDataToAnalytics;

	pageProcessing.setUpListener();

	_T.pageData = pageProcessing.pageDataArray();

};

let processTempPageData = function () {

	let _tempPageData = ( typeof _T.pageData !== 'undefined' && _T.pageData.constructor === Array && _T.pageData.length ? true : false );

	exposeItemsOnInterface();

	if ( _tempPageData ) {
		console.log( LOG_PREFIX + " -- appController _tempPageData: ", _tempPageData );
		appData.addUpdateCache( "beaconExecutionType", "preignition" );
		pageProcessing.sendPageDataToAnalytics();
	}

};

let run = async function( ddo ) {

	storage.checkForExpiredItems();
	
	pagehide.init();

	validateDataObj( ddo );

	eventProcessing.setUpListener();

    let _tempEventData = getTempEventData( ddo );

	if ( !ddo.event || ( Array.isArray( ddo.event ) && ddo.event.push.toString().indexOf( '_aape' ) === -1 ) ) {
		console.log( LOG_PREFIX + " -- appController ddo.event overwrite: TRUE" );
		ddo.event = eventProcessing.eventDataArray();
	}

	/* Set TRON Cache and add pageName */
    appData.addUpdateCache( "pageName", typeof ddo.page.pageInfo.pageName === "string" ? ddo.page.pageInfo.pageName.toLowerCase() : '' );

    await setupModules( ddo );

    await eventProcessing.processPreIgnitionEvents( _tempEventData, ddo );

	processTempPageData();

	if ( BUILD_TARGET === "b2consumer-desktop" || BUILD_TARGET === "b2business-desktop" ) {
		if ( config.featureEnabled( "perimeterx" ) ) {
			perimeterx.initListeners( ddo );
		}
	}

	

};

// iframed Overlay tracking is currently handled through the parent page.  In other words, the analytics events are passed from the Iframe to
// its parent page for execution.  We use the _router method to ensure that the Core.js that IS included on the Iframe Overlay does not execute
// any analtics calls (page or events) but allows for the delivery of certain 3rd party tags (ClickTale and TnT primarily).
let _router = async function( ddo ) {

	let remoteUtils = await import( 'AAPE_Sync/utilities' );

	if ( !remoteUtils.isOverlay() ) {
        run( ddo );
	}

};

let continueInitialization = function() {

	if ( !_aape.PUB_SUB ) {
		_aape.PUB_SUB = pubSub.init();
	}

	if ( !_T.PUB_SUB ) {
		_T.PUB_SUB = pubSub_Compatibility.init();
	}

	if ( document.readyState === "complete" ) {
		console.log( LOG_PREFIX + " -- document.readyState: ", document.readyState );
		_router( window.digitalData );
	}
	else {
		document.addEventListener( 'readystatechange', function () {
			console.log( LOG_PREFIX + " -- readystatechange: ", document.readyState );
			if ( document.readyState === "complete" ) {
				_router( window.digitalData );
			}
		} );
	}

};

( function() {
    // An indicator for build_local mappings 
    console.log( '%c' + LOG_PREFIX + ' -- Local Build Mapping: ' + CODE_VERSION + ' | ' + BUILD_TARGET + ' | 2024-12-11T16:34:50.577Z', 'background: orange; color: white; display: block; line-height:20px; padding:5px 30px; border: 1px solid red; font-size:1em;' );

	window._T = ( window._T ? _T : {} ); // Leave in for Legacy Support
	window._aape = ( window._aape ? _aape : {} );
	window._aape.metrics = ( window._aape.metrics ? window._aape.metrics : {
		events: "",
		linkTrackVars: new Set(),
		linkTrackEvents: new Set()
	} );

    window.digitalData = ( window.digitalData ? digitalData : {
        event: []
    } );
	
    _T.pageData = ( _T.pageData ? _T.pageData : [] );
    _aape.eventData = ( _aape.eventData ? _aape.eventData : [] );
	_aape.deployments = ( _aape.deployments ? _aape.deployments : {} );

	_aape.deployments.core = {
        build: BUILD_TARGET,
        versions: {
            core: CODE_VERSION
        },
        ts: '2024-12-11T16:34:50.577Z',
		host: _aape.scriptHost
    };

	continueInitialization();

} )();